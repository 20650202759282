import ClassicLightTheme from '../../../../../themes/classic/light/theme';
const PinkLightTheme = Object.assign({}, ClassicLightTheme, {
  color: Object.assign({}, ClassicLightTheme.color)
});
Object.keys(PinkLightTheme.color).forEach(key => {
  if (key.startsWith('fill') || key.startsWith('icon')) {
    PinkLightTheme.color[key] = 'pink';
  }
  if (key.startsWith('border')) {
    PinkLightTheme.color[key] = 'red';
  }
});
export default PinkLightTheme;