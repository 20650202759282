import { registerMutation } from 'data-fetching-client';
import { ecosystemAppClient } from 'marketplace-ui-common/clients/ecosystemClient';
export const EDIT_REVIEW = registerMutation({
  fieldName: 'editReviewResponse',
  args: ['review', 'editReason'],
  fetcher({
    review,
    editReason
  }) {
    const {
      answers,
      ratings,
      tags
    } = review.toJSON();
    return ecosystemAppClient.put(`/reviews/${review.id}/edit`, {
      data: {
        answers,
        ratings,
        tags,
        editReason
      }
    });
  }
});