'use es6';

import { useUsageTracker } from 'marketplace-ui-tracking';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
const UsageViewTracker = ({
  active = true,
  name = 'pageview',
  properties = {}
}) => {
  const tracker = useUsageTracker();
  const [tracked, setTracked] = useState(false);
  const getComputedProperties = useCallback(() => {
    return typeof properties === 'function' ? properties() : properties;
  }, [properties]);
  useEffect(() => {
    if (active && !tracked) {
      tracker.track(name, getComputedProperties());
      setTracked(true);
    }
  }, [tracker, active, getComputedProperties, name, tracked]);
  return null;
};
UsageViewTracker.propTypes = {
  active: PropTypes.bool,
  name: PropTypes.string,
  properties: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};
export default UsageViewTracker;