module.exports = {
  "pageview": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "type": [
        "solution provider"
      ]
    },
    "namespace": "marketplace",
    "version": "1"
  },
  "interaction": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "location": {
        "type": "string",
        "isOptional": true
      },
      "item_index": {
        "type": "string",
        "isOptional": true
      },
      "item_type": {
        "type": "string",
        "isOptional": true
      },
      "review_source": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "payment_type": {
        "type": "string",
        "isOptional": true
      },
      "tags": {
        "type": "array",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": "string",
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "type": [
        "solution provider"
      ]
    },
    "namespace": "marketplace",
    "version": "1"
  },
  "profile-pageview": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": [
        "profile"
      ],
      "tags": {
        "type": "array",
        "isOptional": true
      },
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "type": [
        "solution provider"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "marketplace",
    "version": "1"
  },
  "profile-interaction": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "location": {
        "type": "string",
        "isOptional": true
      },
      "item_index": {
        "type": "string",
        "isOptional": true
      },
      "item_type": {
        "type": "string",
        "isOptional": true
      },
      "review_source": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "payment_type": {
        "type": "string",
        "isOptional": true
      },
      "tags": {
        "type": "array",
        "isOptional": true
      },
      "screen": [
        "profile"
      ],
      "action": "string",
      "section": {
        "type": "string",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "type": [
        "solution provider"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "marketplace",
    "version": "1"
  },
  "storefront-pageview": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": [
        "storefront",
        "storefront home"
      ],
      "sorts": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "type": [
        "solution provider"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "service": {
        "type": "string",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "country": {
        "type": "string",
        "isOptional": true
      },
      "state": {
        "type": "string",
        "isOptional": true
      },
      "language": {
        "type": "string",
        "isOptional": true
      },
      "budget": {
        "type": "string",
        "isOptional": true
      },
      "certification": {
        "type": "string",
        "isOptional": true
      },
      "awardName": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "location": {
        "type": "string",
        "isOptional": true
      },
      "sortField": {
        "type": "string",
        "isOptional": true
      },
      "sortOrder": {
        "type": "string",
        "isOptional": true
      },
      "search": {
        "type": "string",
        "isOptional": true
      },
      "searchLength": {
        "type": "number",
        "isOptional": true
      },
      "pageNumber": {
        "type": "number",
        "isOptional": true
      },
      "totalResults": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "marketplace",
    "version": "1"
  },
  "storefront-mobile-filter-panel-view": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": [
        "storefront"
      ],
      "subscreen": [
        "mobile filter panel"
      ],
      "filterKey": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "type": [
        "solution provider"
      ],
      "service": {
        "type": "string",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "country": {
        "type": "string",
        "isOptional": true
      },
      "state": {
        "type": "string",
        "isOptional": true
      },
      "language": {
        "type": "string",
        "isOptional": true
      },
      "budget": {
        "type": "string",
        "isOptional": true
      },
      "certification": {
        "type": "string",
        "isOptional": true
      },
      "awardName": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "location": {
        "type": "string",
        "isOptional": true
      },
      "sortField": {
        "type": "string",
        "isOptional": true
      },
      "sortOrder": {
        "type": "string",
        "isOptional": true
      },
      "search": {
        "type": "string",
        "isOptional": true
      },
      "searchLength": {
        "type": "number",
        "isOptional": true
      },
      "pageNumber": {
        "type": "number",
        "isOptional": true
      },
      "totalResults": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "marketplace",
    "version": "1"
  },
  "storefront-filter-view": {
    "name": "filter",
    "class": "view",
    "properties": {
      "screen": [
        "storefront"
      ],
      "sorts": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "type": [
        "solution provider"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "service": {
        "type": "string",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "country": {
        "type": "string",
        "isOptional": true
      },
      "state": {
        "type": "string",
        "isOptional": true
      },
      "language": {
        "type": "string",
        "isOptional": true
      },
      "budget": {
        "type": "string",
        "isOptional": true
      },
      "certification": {
        "type": "string",
        "isOptional": true
      },
      "awardName": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "location": {
        "type": "string",
        "isOptional": true
      },
      "sortField": {
        "type": "string",
        "isOptional": true
      },
      "sortOrder": {
        "type": "string",
        "isOptional": true
      },
      "search": {
        "type": "string",
        "isOptional": true
      },
      "searchLength": {
        "type": "number",
        "isOptional": true
      },
      "pageNumber": {
        "type": "number",
        "isOptional": true
      },
      "totalResults": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "marketplace",
    "version": "1"
  },
  "storefront-interaction": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "location": {
        "type": "string",
        "isOptional": true
      },
      "item_index": {
        "type": "string",
        "isOptional": true
      },
      "item_type": {
        "type": "string",
        "isOptional": true
      },
      "review_source": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "payment_type": {
        "type": "string",
        "isOptional": true
      },
      "tags": {
        "type": "array",
        "isOptional": true
      },
      "sorts": {
        "type": "string",
        "isOptional": true
      },
      "screen": [
        "storefront",
        "storefront home"
      ],
      "action": "string",
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "type": [
        "solution provider"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "service": {
        "type": "string",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "country": {
        "type": "string",
        "isOptional": true
      },
      "state": {
        "type": "string",
        "isOptional": true
      },
      "language": {
        "type": "string",
        "isOptional": true
      },
      "budget": {
        "type": "string",
        "isOptional": true
      },
      "certification": {
        "type": "string",
        "isOptional": true
      },
      "awardName": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "sortField": {
        "type": "string",
        "isOptional": true
      },
      "sortOrder": {
        "type": "string",
        "isOptional": true
      },
      "search": {
        "type": "string",
        "isOptional": true
      },
      "searchLength": {
        "type": "number",
        "isOptional": true
      },
      "pageNumber": {
        "type": "number",
        "isOptional": true
      },
      "totalResults": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "marketplace",
    "version": "1"
  },
  "marketplaceInteraction": {
    "name": "marketplace interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": {
        "type": [
          "in-app",
          "public"
        ],
        "isOptional": true
      },
      "type": {
        "type": [
          "integration",
          "theme",
          "module"
        ],
        "isOptional": true
      },
      "embed": {
        "type": "boolean",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "sessionUrlParams": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "marketplace"
  },
  "marketplaceClickResultItem": {
    "name": "marketplace interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "click result item"
      ],
      "itemIndex": "number",
      "totalResults": "number",
      "value": "string",
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": {
        "type": [
          "in-app",
          "public"
        ],
        "isOptional": true
      },
      "type": {
        "type": [
          "integration",
          "theme",
          "module"
        ],
        "isOptional": true
      },
      "embed": {
        "type": "boolean",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "sessionUrlParams": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "marketplace"
  },
  "appMarketplaceClickResultItem": {
    "name": "marketplace interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "click result item"
      ],
      "itemIndex": "number",
      "totalResults": "number",
      "value": "string",
      "applicationId": "number",
      "isRecommended": "boolean",
      "category": {
        "type": "string",
        "isOptional": true
      },
      "name": {
        "type": "string",
        "isOptional": true
      },
      "preferredLanguage": {
        "type": "string",
        "isOptional": true
      },
      "recommendationType": {
        "type": "array",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": {
        "type": [
          "in-app",
          "public"
        ],
        "isOptional": true
      },
      "type": {
        "type": [
          "integration",
          "theme",
          "module"
        ],
        "isOptional": true
      },
      "embed": {
        "type": "boolean",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "sessionUrlParams": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "marketplace"
  },
  "solutionsDirectoryClickResultItem": {
    "name": "marketplace interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "click result item"
      ],
      "itemIndex": "number",
      "totalResults": "number",
      "value": "string",
      "tier": "string",
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": {
        "type": [
          "in-app",
          "public"
        ],
        "isOptional": true
      },
      "type": {
        "type": [
          "integration",
          "theme",
          "module"
        ],
        "isOptional": true
      },
      "embed": {
        "type": "boolean",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "sessionUrlParams": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "marketplace"
  }
};