export function getValidationErrors({
  fieldKey,
  fieldType,
  validationObject,
  value
}) {
  const newErrors = [];
  const {
    maxCharacters,
    minCharacters,
    maxSelected,
    minSelected,
    required
  } = validationObject;

  // Required check
  if (required && !value) {
    newErrors.push({
      fieldType,
      fieldKey,
      error: 'required'
    });
  }
  // Max character count check
  if (maxCharacters && value && value.length > maxCharacters) {
    newErrors.push({
      fieldType,
      fieldKey,
      error: 'maxCharacters'
    });
  }
  // Min character count check
  if (minCharacters && (!value || value.length < minCharacters)) {
    newErrors.push({
      fieldType,
      fieldKey,
      error: 'minCharacters'
    });
  }
  // Required tags check
  if (required && fieldType === 'tags' && value && !value.length) {
    newErrors.push({
      fieldType,
      fieldKey,
      error: 'required'
    });
  }
  // Min tag selections check
  if (minSelected && (!value || value.length < minSelected)) {
    newErrors.push({
      fieldType,
      fieldKey,
      error: 'required'
    });
  }
  // Max tag selections check
  if (maxSelected && value && value.length > maxSelected) {
    newErrors.push({
      fieldType,
      fieldKey,
      error: 'required'
    });
  }
  return newErrors;
}