import getLang from 'I18n/utils/getLang';
import { useCallback } from 'react';
import { getAppRecommendationTypes } from '../utils/getAppRecommendationTypes';
import { useTrackEvent } from './useTrackEvent';
export function useTrackAppMarketplaceClickResultItem() {
  const {
    trackEvent
  } = useTrackEvent();
  const trackClickResultItem = useCallback((result, eventProps) => {
    trackEvent('appMarketplaceClickResultItem', Object.assign({}, eventProps, {
      action: 'click result item',
      value: result.slug,
      applicationId: parseInt(result.sourceId, 10),
      isRecommended: !!result.recommendation,
      recommendationType: getAppRecommendationTypes(result),
      preferredLanguage: eventProps.preferredLanguage || getLang()
    }));
  }, [trackEvent]);
  return {
    trackClickResultItem
  };
}
export function useTrackSolutionsDirectoryClickResultItem() {
  const {
    trackEvent
  } = useTrackEvent();
  const trackClickResultItem = useCallback((result, eventProps) => {
    trackEvent('solutionsDirectoryClickResultItem', Object.assign({}, eventProps, {
      action: 'click result item',
      value: result.slug,
      tier: result.tier
    }));
  }, [trackEvent]);
  return {
    trackClickResultItem
  };
}