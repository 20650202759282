import { useCallback } from 'react';
import { useUsageScreen } from './useUsageScreen';
import { useUsageTracker } from './useUsageTracker';
export function useTrackEvent() {
  const tracker = useUsageTracker();
  const {
    screen,
    subscreen
  } = useUsageScreen();
  const trackEvent = useCallback((eventKey, ...[eventProps]) => {
    if (typeof eventKey === 'string') {
      const finalEventProps = Object.assign({}, eventProps);
      const finalScreen = (eventProps === null || eventProps === void 0 ? void 0 : eventProps.screen) || screen;
      const finalSubscreen = (eventProps === null || eventProps === void 0 ? void 0 : eventProps.subscreen) || subscreen;
      if (finalScreen) {
        finalEventProps.screen = finalScreen;
      }
      if (finalSubscreen) {
        finalEventProps.subscreen = finalSubscreen;
      }
      tracker.track(eventKey, finalEventProps);
    }
  }, [screen, subscreen, tracker]);
  return {
    trackEvent
  };
}